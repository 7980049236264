<template>
  <div class="brandBox">
    <pcHeader />
    <div class="coop">
      <img src="../assets/dianshanbg.jpg" />
      <div class="banner-title">{{ $t("lang.commerce.bannerTitle") }}</div>
    </div>
    <div class="branContainer">
      <div class="detail">{{ $t("lang.commerce.detailOne") }}</div>
      <div class="detail">{{ $t("lang.commerce.detailTwo") }}</div>
      <div class="importDetail">{{ $t("lang.commerce.importDetail") }}</div>
      <div class="partContainer">
        <div class="titles">{{ $t("lang.commerce.coreTitle") }}</div>
        <div class="box">
          <div class="part">
            <img src="../assets/part1.jpg" />
            <div class="content">{{ $t("lang.commerce.contentOne") }}</div>
          </div>
          <div class="part">
            <div class="content">{{ $t("lang.commerce.contentTwo") }}</div>
            <img src="../assets/part2.jpg" />
          </div>
          <div class="part">
            <img src="../assets/part3.jpg" />
            <div class="content">{{ $t("lang.commerce.contentThree") }}</div>
          </div>
        </div>
      </div>
    </div>
    <pcfooter />
  </div>
</template>
<script>
import pcHeader from "@/components/pcHeader";
import pcfooter from "@/components/pcFooter";
export default {
  components: {
    pcHeader,
    pcfooter,
  },
};
</script>
<style lang="scss">
.brandBox {
  .coop {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .banner-title {
      position: absolute;
      font-size: 55px;
      color: #fff;
      font-weight: bold;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .branContainer {
    width: 1200px;
    margin: 0 auto;
    margin-top: 120px;
    .detail {
      font-weight: bold;
      font-size: 20px;
      line-height: 36px;
      color: #6d7276;
    }
    .importDetail {
      font-size: 18px;
      color: #6d7276;
      font-weight: bold;
      margin-top: 42px;
    }
  }
  .partContainer {
    margin-top: 120px;
    .titles {
      margin-bottom: 60px;
      font-size: 26px;
      color: #212b35;
      letter-spacing: 0;
      font-weight: bold;
    }
    .box {
      margin: 40px 0;
      display: flex;
      flex-direction: row;
      .part {
        width: 400px;
        //line-height: 0;
        .content {
          width: 100%;
          height: 120px;
          background: #21c3b8;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          font-weight: bold;
          //padding: 0 23px;
          box-sizing: border-box;
          text-align: center;
        }
        .content:nth-child(2) {
          background: #618fab;
        }
        img:nth-child(2n + 1) {
          display: block;
        }
      }
    }
  }
}
</style>
